const theme = {
   token: {
      colorPrimary: '#465EFC',
      fontFamily: 'Inter Variable, sans-serif',
      fontSize: 15,
      colorBgBase: '#fff',
   },
};

export default theme;
